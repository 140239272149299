@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;

.root {
  padding: 35px 0 20px;
  background-color: variable.$whiteColor;
  text-align: center;
  order: 3;
  .title {
    font-family: variable.$Freeman;
    color: variable.$primaryColor;
    font-size: 7vw;
    margin-bottom: 20px;
    strong {
      font-family: variable.$CaveatBrush;
      font-size: 6.5vw;
    }
  }
  .content {
    padding: 0 5px;
    .video {
      img {
        width: 100%;
        height: auto;
      }
      .logo {
        img {
          width: 250px;
          margin: auto;
          display: block;
        }
      }
      .feature {
        margin-bottom: 25px;
        border-radius: 18px;
        border: 1px solid variable.$primaryColor;
        overflow: hidden;
        cursor: pointer;
        position: relative;
        &::after,
        &::before {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          mask-image: url("/static/images/icons/button-play-violet.png");
          mask-repeat: no-repeat;
          mask-position: center;
          z-index: 2;
          background-color: variable.$whiteColor;
        }
        &::before {
          mask: none;
          background-color: rgba($color: #000000, $alpha: 0.3);
        }
      }
      .logo {
        margin-bottom: 25px;
      }
    }
    .info {
      border-radius: 20px;
      border-bottom-left-radius: 0;
      background: variable.$orangeColor;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
      padding: 15px;
      margin-bottom: 30px;
      .subtitle {
        font-family: variable.$primaryFontBold;
        color: variable.$whiteColor;
        font-size: 5.5vw;
        text-align: left;
        margin-bottom: 15px;
        strong {
          font-size: 26px;
          font-family: "Freeman";
          line-height: 70px;
          padding-left: 50px;
          background-repeat: no-repeat;
          background-position: left center;
          background-image: url("/static/images/icons/info.png");
          background-size: contain;
        }
      }
      .description {
        font-family: variable.$primaryFont;
        color: variable.$blackColor;
        font-size: 4.3vw;
        text-align: left;
        img {
          display: inline-block;
          max-width: 165px;
          vertical-align: top;
          margin-right: 5px;
        }
        p {
          display: inline;
        }
      }
    }
    .list {
      text-align: left;
      padding: 0;
      span {
        display: flex;
        align-items: center;
        font-family: variable.$primaryFontSemiBold;
        color: variable.$primaryColor;
        font-size: 4.4vw;
        line-height: 5vw;
        margin-bottom: 10px;
        &::before {
          content: "";
          display: inline-block;
          width: 1em;
          min-width: 1em;
          height: 1.5em;
          mask-repeat: no-repeat;
          mask-position: left center;
          mask-image: url("/static/images/icons/check-circle-white.png");
          mask-size: 1em auto;
          background-color: currentColor;
          margin-right: 5px;
          position: relative;
        }
      }
      p {
        font-family: variable.$primaryFont;
        color: variable.$blackColor;
        font-size: 4.4vw;
      }
      li {
        margin-bottom: 25px;
      }
    }
  }
  .action {
    a {
      max-width: max-content;
      padding: 0 20px;
      box-shadow: none;
      margin: 0 auto;
      border-width: 1.5px;
    }
  }
}

@include mixin.mediaTabletSize() {
  .root {
    padding: 50px 0;
    .title {
      font-size: variable.$bigSize;
      margin-bottom: 15px;
      padding: 0 15px;
      strong {
        font-size: 40px;
      }
      p {
        font-size: variable.$size34;
      }
      > * {
        padding-bottom: 13px;
        background-size: 140px auto;
        background-position: right bottom;
        &::after {
          width: 15px;
          height: 32px;
          margin-left: 10px;
        }
      }
    }
    .content {
      flex-direction: row-reverse;
      align-items: center;
      margin-bottom: 10px;
      .video {
        width: 40%;
        display: flex;
        flex-direction: column;
        .logo {
          img {
            max-width: 340px;
            margin: 0 auto;
          }
        }
      }
      .presentation {
        width: 56%;
      }
      .info {
        margin-bottom: 20px;
        .subtitle {
          font-size: variable.$size20;
          margin-bottom: 20px;
          p {
            padding-left: 60px;
            text-indent: -60px;
            line-height: 10px;
          }
          strong {
            line-height: 60px;
            font-size: 34px;
            padding-left: 60px;
          }
        }
        .description {
          font-size: variable.$size16;
          padding-left: 60px;
        }
      }
      .list {
        padding: 0;
        span {
          font-size: variable.$size20;
          line-height: inherit;
          &::before {
            width: 22px;
            height: 22px;
            background-size: 22px auto;
          }
        }
        p {
          font-size: variable.$size16;
        }
        li {
          margin-bottom: 25px;
        }
      }
    }
  }
}
@include mixin.mediaTablet {
  .root {
    .content {
      flex-direction: column-reverse;
      .presentation,
      .video {
        width: 100%;
      }
    }
  }
}
@include mixin.mediaMobile() {
  .root {
    .title {
      img {
        max-width: 240px;
      }
      .logo {
        &:after {
          width: 30px;
          height: 26px;
        }
      }
    }
  }
}
